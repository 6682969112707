@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2c/src/style/abstract/variables';
@import '../../../../advox-strigo-b2c/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2c/src/style/abstract/button';

:root {
    --checkout-payment-title-color: #{$black};
    --checkout-payment-border-radius: 10px;
    --checkout-payment-border-color: #{$default-secondary-base-color};
}

[dir='ltr'] .CheckoutPayment {
    padding: 0;
    border: 0;
    margin-left: -1px;
    margin-right: -1px;
    margin-top: -1px;

    &:first-child .CheckoutPayment-Button {
        border-radius: var(--checkout-payment-border-radius) var(--checkout-payment-border-radius) 0 0;
    }

    &:last-child .CheckoutPayment-Button {
        border-radius: 0 0 var(--checkout-payment-border-radius) var(--checkout-payment-border-radius);
    }

    &::before,
    &::after {
        content: '';
        display: block;
        background: $white;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 1px;
        right: 1px;
        width: auto;
        z-index: -1;
    }

    &::before {
        top: 0;
        left: 1px;
        right: 1px;
    }

    &:hover {
        &::before,
        &::after {
            z-index: 1;
        }

        .CheckoutPayment-Button {
            border-radius: var(--checkout-payment-border-radius);
            border-color: var(--checkout-payment-border-color);
            z-index: 2;
        }

        .CheckoutPayment-MethodIcon > svg {
            opacity: 1;
        }
    }

    &_isSelected {
        &::before,
        &::after {
            z-index: 1;
        }

        &:first-child .CheckoutPayment-Button,
        .CheckoutPayment-Button {
            border-radius: var(--checkout-payment-border-radius);
            border-color: var(--checkout-payment-border-color);
        }

        &:last-child .CheckoutPayment-Button {
            border-radius: var(--checkout-payment-border-radius);
        }

        .CheckoutPayment-Button {
            z-index: 2;
        }

        .CheckoutPayment-MethodIcon > svg {
            opacity: 1;
        }
    }

    &-Button {
        width: 100%;
        padding: 19px 13px;
        border: 1px solid $grey5;
        background: $white;
        display: flex;
        align-items: center;

        &_isDisabled {
            background: $grey-light;
        }

        @include from-tablet {
            padding: 17px 22px 17px 33px;
            width: 100%;
            min-height: 65px;
        }

        > svg {
            min-width: 16px;
            width: 16px;
            height: 16px;
            margin-right: 20px;
            rect:first-of-type {
                stroke: $grey-dark;
            }
            @include from-tablet {
                margin-right: 35px;
            }
        }

        &_isSelected {
            > svg {
                rect:first-of-type {
                    stroke: $orange50;
                }
            }
        }
    }

    &-MethodIcon {
        display: flex;
        align-items: center;
        max-width: 62px;
        min-width: 62px;
        padding: 0;
        margin-right: 30px;

        @include from-tablet {
            max-width: 72px;
            min-width: 72px;
            margin-right: 50px;
        }

        svg {
            opacity: 0.5;
            max-width: 100%;
            margin: 0 auto;
        }

        &_twoIcons {
            max-width: 72px;
            min-width: 72px;
            margin-left: -10px;

            @include from-tablet {
                max-width: 82px;
                min-width: 82px;
            }
            svg:first-of-type {
                margin-left: 5px;
            }
            svg:last-of-type {
                margin-right: -5px;
            }
        }
    }

    &-MethodTitle {
        font-family: $font-secondary;
        font-weight: normal;
        font-size: 15px;
        line-height: 150%;
        color: var(--checkout-payment-title-color);
        text-align: left;
    }
}
