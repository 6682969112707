@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2c/src/style/abstract/variables';
@import '../../../../advox-strigo-b2c/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2c/src/style/abstract/button';

:root {
    --inpost-selected-parcel-title-color: #{$orange50};
    --inpost-search-border-color: #{$orange10};
    --inpost-info-box-border-color: #{$orange50};
    --inpost-info-select-btn-background: #{$orange50};
}

.CheckoutInpost {
    display: block;

    &-SelectedParcelLocker {
        margin: 20px 0 0 30px;

        @include from-tablet {
            margin: 20px 0 0 143px;
        }

        &Address {
            display: flex;
            flex-direction: column;
            margin: 15px 0 0;

            > span {
                font-size: 13px;

                &:first-of-type {
                    font-weight: 700;
                }
            }
        }
    }

    &-SelectedParcelLockerTitle {
        font-size: 13px;
        font-weight: 500;
        color: var(--inpost-selected-parcel-title-color);
    }
}

[dir='ltr'] {
    .InpostMapPopup {
        .StoreInPickUpPopup-Heading {
            position: absolute;
            color: $blue50;
            font-size: 22px;
            left: 25px;
            top: 25px;
        }

        .Popup-Header {
            border: none;

            @include desktop {
                padding: 25px 30px;
            }
        }

        .NotificationList {
            left: 0;
            background: var(--popup-header-background);
        }

        .Popup-Content {
            width: 1080px;
            height: 100vh;
            max-width: 100vw;
            max-height: 100vh;
            padding: 0;
            overflow: hidden;

            @include desktop {
                height: 555px;
            }
        }

        .Popup-CloseBtn {
            right: 15px;
            top: 15px;
        }

        .easypack-widget {
            max-height: calc(100% - 94px);

            @include mobileAndTablet {
                padding: 0;
            }

            @include tablet {
                max-height: calc(100% - 64px);
            }

            @include mobile {
                max-height: calc(100% - 80px);
            }

            .view-chooser,
            .list-widget {
                display: none !important;
            }

            .search-widget {
                background: none;
                width: 100%;
                display: flex;
                justify-content: flex-end;

                @include from-tablet {
                    width: 55%;
                    position: absolute;
                    bottom: 100%;
                }

                @include desktop {
                    left: 30%;
                }

                @include tablet {
                    left: 50%;
                    width: 50%;
                    transform: translateX(-25%);
                }

                @include mobile {
                    width: 100%;
                    justify-content: stretch;
                    background: var(--popup-header-background);
                }

                .search-group:not(.with-filters) {
                    position: relative;
                    padding: 0 0 10px;

                    @include desktop {
                        padding: 22px 0;
                    }

                    @include tablet {
                        padding: 12px 0;
                    }

                    @include mobile {
                        width: 100%;
                        padding: 0 15px 10px;
                    }

                    .search-input {
                        border: 1px solid var(--inpost-search-border-color);
                        border-radius: 5px;
                        height: 40px;

                        @include mobile {
                            padding-right: 45px;
                        }
                    }

                    #searchLoader {
                        position: absolute;
                        right: 60px;
                        top: 1px;
                        height: 38px;
                        background-color: transparent;
                        > .ball-spin-fade-loader {
                            top: 12px;
                        }
                    }

                    .search-group-btn {
                        position: absolute;
                        right: 30px;

                        @include mobile {
                            right: 45px;
                        }

                        .btn-search {
                            background-image: url('../../assets/svg/search.svg') !important;
                            background-color: transparent !important;
                            border: none;
                            height: 40px;
                            min-height: unset;
                            width: 20px;
                        }
                    }
                }

                .inpost-search__list {
                    left: unset;
                    right: 0;
                    width: 70%;
                    top: 40px;

                    @include mobile {
                        width: 100%;
                        left: 0;
                    }
                }
            }

            .leaflet-popup,
            .info-box-wrapper {
                .leaflet-popup-content-wrapper,
                .info-window .content {
                    border: 1px solid var(--inpost-info-box-border-color);
                    background: $white !important;
                    padding: 15px 5px 5px;
                    .point-wrapper {
                        text-align: left;

                        h1 {
                            display: inline;
                            color: #000 !important;
                            font-size: 15px;
                            font-family: inherit;

                            + p {
                                display: inline;
                                font-weight: 700;
                                margin-left: 5px;
                                font-size: 15px;
                            }
                        }
                    }

                    .links {
                        border: none;

                        .route-link,
                        .details-link {
                            display: none !important;
                        }

                        .select-link {
                            background: var(--inpost-info-select-btn-background);
                            border: none;
                            padding: 10px;
                        }
                    }

                    .apm_doubled {
                        margin: 10px 0 0;
                        padding: 10px 0 0;
                        color: $black;
                        text-align: left !important;
                        align-items: flex-start !important;
                    }
                }
                .leaflet-popup-tip-container {
                    display: none;
                }

                .leaflet-popup-close-button {
                    color: transparent !important;
                    font-size: 0;
                    width: 20px;
                    height: 20px;
                    padding: 0;
                    z-index: 1;

                    &::before {
                        position: absolute;
                        left: -5px;
                        top: 8px;
                        width: 20px;
                        height: 20px;
                        visibility: visible;
                        content: url('../../assets/svg/close-circle.svg');
                    }
                }

                > img {
                    padding: 0;
                    z-index: 1;
                    width: 15px;
                    height: 15px;
                    top: 30px;
                }
            }
        }
    }
    .CheckoutDeliveryOption {
        &-InpostButton {
            &.Button {
                margin: 20px 0 0 30px;
                max-width: 230px;
                width: calc(100% - 50px);
                height: 40px;
                font-size: 13px;

                @include from-tablet {
                    margin: 20px 0 0 143px;
                    min-width: 220px;
                    max-width: 250px;
                }
            }
        }
    }
}
