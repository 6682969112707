@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2c/src/style/abstract/variables';
@import '../../../../advox-strigo-b2c/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2c/src/style/abstract/button';

:root {
    --checkout-payments-border-color: #{$grey5};
    --checkout-payments-border-radius: 10px;
}

.CheckoutPayments {
    margin-top: 40px;

    @include desktop {
        margin-top: 70px;
    }

    &-Methods {
        border-right: 1px solid var(--checkout-payments-border-color);
        border-left: 1px solid var(--checkout-payments-border-color);
        border-radius: var(--checkout-payments-border-radius);

        @include wide-desktop {
            width: 100%;
        }
    }
}
